import { Button } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  loadInvestmentAccount,
  loadInvestmentAccountDocuments,
  updateInvestmentAccount,
  uploadInvestmentAccountDocuments
} from 'redux/actions/account';
import { change, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { AccountDocument, SingleInvestmentAccount } from 'types/api/account';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { handleErrorResponse } from 'utilities/errorHandlers';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { isComplete } from '../helpers';
import { jointSecondaryHolderIdentificationSchema } from '../validation';
import AccountWrap from './AccountWrap/AccountWrap';
import BackLink from './BackLink/BackLink';
import IdentificationDocumentFields from './FormFields/IdentificationDocumentFields';

interface Params {
  reference_id: string;
}

interface CustomProps {
  dispatch: Dispatch;
  idDoc: AccountDocument;
  investmentAccount: SingleInvestmentAccount;
  loading: boolean;
  residenceStatus: string;
  submitting: boolean;
  uploading: boolean;
}

type PropsBeforeReduxForm = RouteComponentProps<Params> & CustomProps;

type Props = InjectedFormProps<object, PropsBeforeReduxForm> &
  PropsBeforeReduxForm;

const JointSecondaryHolderIdentification = ({
  dispatch,
  handleSubmit,
  idDoc,
  investmentAccount,
  loading,
  match,
  residenceStatus,
  submitting,
  uploading
}: Props) => {
  useEffect(() => {
    document.title =
      'My Accounts | Joint Account Secondary Account Holder Identification';
    dispatch(loadInvestmentAccount(match.params.reference_id))
      .then(() =>
        dispatch(
          loadInvestmentAccountDocuments(match.params.reference_id)
        ).catch(error => handleErrorResponse(error))
      )
      .catch(error => handleErrorResponse(error));
  }, [dispatch, match]);

  const onSubmit = values => {
    values.investment_account.id = investmentAccount.id;
    values.investment_account.account = 'secondary';
    values.file2 = values.investment_account.account_holder_2_id_document;
    return dispatch(uploadInvestmentAccountDocuments(values))
      .then(() => {
        dispatch(updateInvestmentAccount(values));
        EmAnalytics.track(
          'Submits Joint Identification Document',
          'Onboarding'
        );
        if (isComplete(investmentAccount)) {
          history.push(
            `/accounts/update/joint/documents/${investmentAccount.reference_id}`
          );
        } else {
          history.push(
            `/accounts/wizard/joint/w9/${investmentAccount.reference_id}`
          );
        }
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  const handleFileRemoval = () => {
    dispatch(
      change(
        'investment_account',
        'investment_account.account_holder_2_id_document',
        null
      )
    );
  };

  const handleUpload = file => {
    dispatch(
      change(
        'investment_account',
        'investment_account.account_holder_2_id_document',
        file
      )
    );
  };

  return (
    <AccountWrap loading={loading} account={investmentAccount}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h4 className="margin-top-0">
          Verify Secondary Account Holder's Identity
        </h4>
        <p className="margin-xx">
          Please upload a color copy of a U.S government-issued document. The
          name reflected on the identification document must match the name
          associated with the secondary account holder.
        </p>
        <IdentificationDocumentFields
          isSecondaryAccountHolder
          existingDocument={idDoc}
          onRemove={handleFileRemoval}
          onUpload={handleUpload}
          residenceStatus={residenceStatus}
          uploading={uploading}
        />

        <div className="forwardBackButtonWrapCompact">
          <Button
            type="submit"
            loading={submitting}
            style={{ width: 160 }}
            variant="orange"
          >
            {isComplete(investmentAccount) ? 'Update' : 'Continue'}
          </Button>
          <BackLink
            investmentAccount={investmentAccount}
            page="Joint Account Identification"
          />
        </div>
      </Form>
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  const residenceStatus = state.auth.user?.investor_profile?.residence_status;
  const idDoc = state.account.documents?.account_holder_2_id_document;
  const investmentAccountInitialValues = idDoc
    ? {
        account_holder_2_dob:
          state.account.investmentAccount?.account_holder_2_dob &&
          moment
            .utc(state.account.investmentAccount.account_holder_2_dob)
            .format('MM/DD/YYYY'),
        account_holder_2_confirm_valid_id: true,
        account_holder_2_id_document: idDoc.name,
        account_holder_2_id_type: idDoc.type,
        account_holder_2_id_expiration: idDoc.expiry
          ? moment.utc(idDoc.expiry).format('MM/DD/YYYY')
          : null
      }
    : {};

  return {
    idDoc,
    initialValues: { investment_account: investmentAccountInitialValues },
    investmentAccount: state.account.investmentAccount,
    loading: state.account.loading,
    residenceStatus,
    submitting: state.account.submitting,
    uploading: state.account.uploading
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<object, Props>({
    form: 'investment_account',
    enableReinitialize: true,
    destroyOnUnmount: false,
    touchOnBlur: false,
    onSubmitFail: scrollToError,
    validate: validateSchema(jointSecondaryHolderIdentificationSchema)
  })(JointSecondaryHolderIdentification)
);
