export const LOAD_ALL = 'account/LOAD_ALL';
export const LOAD_ALL_SUCCESS = 'account/LOAD_ALL_SUCCESS';
export const LOAD_ALL_FAIL = 'account/LOAD_ALL_FAIL';
export const LOAD_INVESTMENT_ACCOUNT = 'account/LOAD_INVESTMENT_ACCOUNT';
export const LOAD_INVESTMENT_ACCOUNT_SUCCESS =
  'account/LOAD_INVESTMENT_ACCOUNT_SUCCESS';
export const LOAD_INVESTMENT_ACCOUNT_FAIL =
  'account/LOAD_INVESTMENT_ACCOUNT_FAIL';
export const LOAD_INVESTMENT_ACCOUNT_DOCUMENTS =
  'account/LOAD_INVESTMENT_ACCOUNT_DOCUMENTS';
export const LOAD_INVESTMENT_ACCOUNT_DOCUMENTS_SUCCESS =
  'account/LOAD_INVESTMENT_ACCOUNT_DOCUMENTS_SUCCESS';
export const LOAD_INVESTMENT_ACCOUNT_DOCUMENTS_FAIL =
  'account/LOAD_INVESTMENT_ACCOUNT_DOCUMENTS_FAIL';
export const LOAD_BENEFICIAL_OWNER = 'account/LOAD_BENEFICIAL_OWNER';
export const LOAD_BENEFICIAL_OWNER_SUCCESS =
  'account/LOAD_BENEFICIAL_OWNER_SUCCESS';
export const LOAD_BENEFICIAL_OWNER_FAIL = 'account/LOAD_BENEFICIAL_OWNER_FAIL';
export const CREATE_BENEFICIAL_OWNER = 'account/CREATE_BENEFICIAL_OWNER';
export const CREATE_BENEFICIAL_OWNER_SUCCESS =
  'account/CREATE_BENEFICIAL_OWNER_SUCCESS';
export const CREATE_BENEFICIAL_OWNER_FAIL =
  'account/CREATE_BENEFICIAL_OWNER_FAIL';
export const UPDATE_BENEFICIAL_OWNER = 'account/UPDATE_BENEFICIAL_OWNER';
export const UPDATE_BENEFICIAL_OWNER_SUCCESS =
  'account/UPDATE_BENEFICIAL_OWNER_SUCCESS';
export const UPDATE_BENEFICIAL_OWNER_FAIL =
  'account/UPDATE_BENEFICIAL_OWNER_FAIL';
export const REMOVE_BENEFICIAL_OWNER = 'account/REMOVE_BENEFICIAL_OWNER';
export const REMOVE_BENEFICIAL_OWNER_SUCCESS =
  'account/REMOVE_BENEFICIAL_OWNER_SUCCESS';
export const REMOVE_BENEFICIAL_OWNER_FAIL =
  'account/REMOVE_BENEFICIAL_OWNER_FAIL';
export const LOAD_BANK_ACCOUNT = 'account/LOAD_BANK_ACCOUNT';
export const LOAD_BANK_ACCOUNT_SUCCESS = 'account/LOAD_BANK_ACCOUNT_SUCCESS';
export const LOAD_BANK_ACCOUNT_FAIL = 'account/LOAD_BANK_ACCOUNT_FAIL';
export const CREATE_BANK_ACCOUNT = 'account/CREATE_BANK_ACCOUNT';
export const CREATE_BANK_ACCOUNT_SUCCESS =
  'account/CREATE_BANK_ACCOUNT_SUCCESS';
export const CREATE_BANK_ACCOUNT_FAIL = 'account/CREATE_BANK_ACCOUNT_FAIL';
export const REMOVE_BANK_ACCOUNT = 'account/REMOVE_BANK_ACCOUNT';
export const REMOVE_BANK_ACCOUNT_SUCCESS =
  'account/REMOVE_BANK_ACCOUNT_SUCCESS';
export const REMOVE_BANK_ACCOUNT_FAIL = 'account/REMOVE_BANK_ACCOUNT_FAIL';
export const UPDATE_W9_FORM = 'account/UPDATE_W9_FORM';
export const UPDATE_W9_FORM_SUCCESS = 'account/UPDATE_W9_FORM_SUCCESS';
export const UPDATE_W9_FORM_FAIL = 'account/UPDATE_W9_FORM_FAIL';
export const LOAD_CLASSIFICATIONS = 'account/LOAD_CLASSIFICATIONS';
export const LOAD_CLASSIFICATIONS_SUCCESS =
  'account/LOAD_CLASSIFICATIONS_SUCCESS';
export const LOAD_CLASSIFICATIONS_FAIL = 'account/LOAD_CLASSIFICATIONS_FAIL';
export const SUBMIT_INVESTMENT_ACCOUNT = 'account/SUBMIT_INVESTMENT_ACCOUNT';
export const SUBMIT_INVESTMENT_ACCOUNT_SUCCESS =
  'account/SUBMIT_INVESTMENT_ACCOUNT_SUCCESS';
export const SUBMIT_INVESTMENT_ACCOUNT_FAIL =
  'account/SUBMIT_INVESTMENT_ACCOUNT_FAIL';
export const UPDATE_POA_FORM = 'account/UPDATE_POA_FORM';
export const UPDATE_POA_FORM_SUCCESS = 'account/UPDATE_POA_FORM_SUCCESS';
export const UPDATE_POA_FORM_FAIL = 'account/UPDATE_POA_FORM_FAIL';
export const CHECK_DOCUMENT_STATUS = 'account/CHECK_DOCUMENT_STATUS';
export const CHECK_DOCUMENT_STATUS_SUCCESS =
  'account/CHECK_DOCUMENT_STATUS_SUCCESS';
export const CHECK_DOCUMENT_STATUS_FAIL = 'account/CHECK_DOCUMENT_STATUS_FAIL';
export const CLEAR_INVESTMENT_ACCOUNT = 'account/CLEAR_INVESTMENT_ACCOUNT';
export const UPLOAD_INVESTMENT_ACCOUNT_DOCUMENT =
  'account/UPLOAD_INVESTMENT_ACCOUNT_DOCUMENT';
export const UPLOAD_INVESTMENT_ACCOUNT_DOCUMENT_SUCCESS =
  'account/UPLOAD_INVESTMENT_ACCOUNT_DOCUMENT_SUCCESS';
export const UPLOAD_INVESTMENT_ACCOUNT_DOCUMENT_FAIL =
  'account/UPLOAD_INVESTMENT_ACCOUNT_DOCUMENT_FAIL';
export const UPLOAD_W9_OFFLINE = 'account/UPLOAD_W9_OFFLINE';
export const UPLOAD_W9_OFFLINE_SUCCESS = 'account/UPLOAD_W9_OFFLINE_SUCCESS';
export const UPLOAD_W9_OFFLINE_FAIL = 'account/UPLOAD_W9_OFFLINE_FAIL';
export const UPLOAD_PROFESSIONAL_LETTER = 'accounts/UPLOAD_PROFESSIONAL_LETTER';
export const UPLOAD_PROFESSIONAL_LETTER_SUCCESS =
  'accounts/UPLOAD_PROFESSIONAL_LETTER_SUCCESS';
export const UPLOAD_PROFESSIONAL_LETTER_FAIL =
  'accounts/UPLOAD_PROFESSIONAL_LETTER_FAIL';
export const LOAD_PENDING_INVESTMENTS = 'accounts/LOAD_PENDING_INVESTMENTS';
export const LOAD_PENDING_INVESTMENTS_SUCCESS =
  'accounts/LOAD_PENDING_INVESTMENTS_SUCCESS';
export const LOAD_PENDING_INVESTMENTS_FAIL =
  'accounts/LOAD_PENDING_INVESTMENTS_FAIL';

export function loadInvestmentAccounts() {
  return {
    types: [LOAD_ALL, LOAD_ALL_SUCCESS, LOAD_ALL_FAIL],
    promise: client => client.get('/mkt/accounts')
  };
}

export function loadPendingInvestments(referenceId) {
  return {
    types: [
      LOAD_PENDING_INVESTMENTS,
      LOAD_PENDING_INVESTMENTS_SUCCESS,
      LOAD_PENDING_INVESTMENTS_FAIL
    ],
    promise: client =>
      client.get(
        `/mkt/accounts/${referenceId}/investments_pending_accreditation`
      )
  };
}

export function getBusinessClassifications() {
  return {
    types: [
      LOAD_CLASSIFICATIONS,
      LOAD_CLASSIFICATIONS_SUCCESS,
      LOAD_CLASSIFICATIONS_FAIL
    ],
    promise: client => client.get('/mkt/accounts/business_classifications')
  };
}

export function loadInvestmentAccount(referenceId) {
  return {
    types: [
      LOAD_INVESTMENT_ACCOUNT,
      LOAD_INVESTMENT_ACCOUNT_SUCCESS,
      LOAD_INVESTMENT_ACCOUNT_FAIL
    ],
    promise: client => client.get(`/mkt/accounts/${referenceId}`)
  };
}

export function loadInvestmentAccountDocuments(referenceId) {
  return {
    types: [
      LOAD_INVESTMENT_ACCOUNT_DOCUMENTS,
      LOAD_INVESTMENT_ACCOUNT_DOCUMENTS_SUCCESS,
      LOAD_INVESTMENT_ACCOUNT_DOCUMENTS_FAIL
    ],
    promise: client => client.get(`/mkt/accounts/${referenceId}/documents`)
  };
}

export function createInvestmentAccount(data) {
  return {
    types: [
      SUBMIT_INVESTMENT_ACCOUNT,
      SUBMIT_INVESTMENT_ACCOUNT_SUCCESS,
      SUBMIT_INVESTMENT_ACCOUNT_FAIL
    ],
    promise: client => client.post('/mkt/accounts', { data })
  };
}

export function updateInvestmentAccount(data) {
  return {
    types: [
      SUBMIT_INVESTMENT_ACCOUNT,
      SUBMIT_INVESTMENT_ACCOUNT_SUCCESS,
      SUBMIT_INVESTMENT_ACCOUNT_FAIL
    ],
    promise: client =>
      client.put(`/mkt/accounts/${data.investment_account.id}`, {
        data
      })
  };
}

export function completeInvestmentAccount(id) {
  return {
    types: [
      LOAD_INVESTMENT_ACCOUNT,
      LOAD_INVESTMENT_ACCOUNT_SUCCESS,
      LOAD_INVESTMENT_ACCOUNT_FAIL
    ],
    promise: client => client.post(`/mkt/accounts/${id}/complete`)
  };
}

export function uploadInvestmentAccountDocuments(data) {
  if (!data.file) data.file = null;
  if (!data.file2) data.file2 = null;

  return {
    types: [
      UPLOAD_INVESTMENT_ACCOUNT_DOCUMENT,
      UPLOAD_INVESTMENT_ACCOUNT_DOCUMENT_SUCCESS,
      UPLOAD_INVESTMENT_ACCOUNT_DOCUMENT_FAIL
    ],
    promise: client =>
      client.post(
        `/mkt/accounts/${data.investment_account.id}/update_documents`,
        {
          field: data.investment_account,
          attach: { file: data.file, file2: data.file2 }
        }
      )
  };
}

export function uploadProfessionalLetter(accountId, file) {
  return {
    types: [
      UPLOAD_PROFESSIONAL_LETTER,
      UPLOAD_PROFESSIONAL_LETTER_SUCCESS,
      UPLOAD_PROFESSIONAL_LETTER_FAIL
    ],
    promise: client =>
      client.post(`/mkt/accounts/${accountId}/upload_professional_letter`, {
        attach: {
          file
        }
      })
  };
}

export function loadBeneficialOwner(id) {
  return {
    types: [
      LOAD_BENEFICIAL_OWNER,
      LOAD_BENEFICIAL_OWNER_SUCCESS,
      LOAD_BENEFICIAL_OWNER_FAIL
    ],
    promise: client => client.get(`/mkt/beneficial_owners/${id}`)
  };
}

export function createBeneficialOwner(accountId, data) {
  delete data.beneficial_owner.beneficialOwners;
  return {
    types: [
      CREATE_BENEFICIAL_OWNER,
      CREATE_BENEFICIAL_OWNER_SUCCESS,
      CREATE_BENEFICIAL_OWNER_FAIL
    ],
    promise: client =>
      data.beneficial_owner.document
        ? client.post('/mkt/beneficial_owners', {
            field: { account_id: accountId, ...data.beneficial_owner },
            attach: { document: data.beneficial_owner.document }
          })
        : client.post('/mkt/beneficial_owners', {
            data: { account_id: accountId, ...data.beneficial_owner }
          })
  };
}

export function updateBeneficialOwner(data) {
  return {
    types: [
      UPDATE_BENEFICIAL_OWNER,
      UPDATE_BENEFICIAL_OWNER_SUCCESS,
      UPDATE_BENEFICIAL_OWNER_FAIL
    ],
    promise: client =>
      data.beneficial_owner.document
        ? client.put(`/mkt/beneficial_owners/${data.beneficial_owner.id}`, {
            field: data.beneficial_owner,
            attach: { document: data.beneficial_owner.document }
          })
        : client.put(`/mkt/beneficial_owners/${data.beneficial_owner.id}`, {
            data: data.beneficial_owner
          })
  };
}

export function removeBeneficialOwner(beneficialOwnerId) {
  return {
    types: [
      REMOVE_BENEFICIAL_OWNER,
      REMOVE_BENEFICIAL_OWNER_SUCCESS,
      REMOVE_BENEFICIAL_OWNER_FAIL
    ],
    promise: client => client.del(`/mkt/beneficial_owners/${beneficialOwnerId}`)
  };
}

export function loadBankAccount(id) {
  return {
    types: [
      LOAD_BANK_ACCOUNT,
      LOAD_BANK_ACCOUNT_SUCCESS,
      LOAD_BANK_ACCOUNT_FAIL
    ],
    promise: client => client.get(`/mkt/linkedbanks/${id}`)
  };
}

export function makePrimaryBankAccount(id) {
  return {
    types: [
      LOAD_BANK_ACCOUNT,
      LOAD_BANK_ACCOUNT_SUCCESS,
      LOAD_BANK_ACCOUNT_FAIL
    ],
    promise: client => client.post(`/mkt/linkedbanks/${id}/make_primary`)
  };
}

export function removeBankAccount(fundingSourceId) {
  return {
    types: [
      REMOVE_BANK_ACCOUNT,
      REMOVE_BANK_ACCOUNT_SUCCESS,
      REMOVE_BANK_ACCOUNT_FAIL
    ],
    promise: client => client.del(`/mkt/linkedbanks/${fundingSourceId}`)
  };
}

export function generateW9Form(userId, investmentAccountId) {
  return {
    types: [UPDATE_W9_FORM, UPDATE_W9_FORM_SUCCESS, UPDATE_W9_FORM_FAIL],
    promise: client =>
      client.post('/mkt/sign_documents/prepare_w9_form', {
        data: {
          user_id: userId,
          investment_account_id: investmentAccountId
        }
      })
  };
}

export function offlineW9Upload(val) {
  return {
    types: [
      UPLOAD_W9_OFFLINE,
      UPLOAD_W9_OFFLINE_SUCCESS,
      UPLOAD_W9_OFFLINE_FAIL
    ],
    promise: client =>
      client.post('/mkt/documents/upload_w9', {
        field: val.data,
        attach: { attachment: val.attachment }
      })
  };
}

export function generatePOADocument(userId, investmentAccountId) {
  return {
    types: [UPDATE_POA_FORM, UPDATE_POA_FORM_SUCCESS, UPDATE_POA_FORM_FAIL],
    promise: client =>
      client.post('/mkt/sign_documents/prepare_poa_agreement_form', {
        data: {
          user_id: userId,
          investment_account_id: investmentAccountId
        }
      })
  };
}

export function checkDocumentSignStatus(referenceId, type) {
  return {
    types: [
      CHECK_DOCUMENT_STATUS,
      CHECK_DOCUMENT_STATUS_SUCCESS,
      CHECK_DOCUMENT_STATUS_FAIL
    ],
    promise: client =>
      client.get(`/mkt/accounts/${referenceId}/document_status/${type}`)
  };
}

export function clearInvestmentAccount() {
  return {
    type: CLEAR_INVESTMENT_ACCOUNT
  };
}
