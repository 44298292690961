import { Button } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import {
  ReduxFormDateSelectField,
  ReduxFormFileUploaderField,
  ReduxFormInputField,
  ReduxFormSelectField
} from 'components/ReduxFormFields';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  createBeneficialOwner,
  loadInvestmentAccount
} from 'redux/actions/account';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import { SingleInvestmentAccount } from 'types/api/account';
import { Dispatch } from 'types/redux';
import { maxUploadSize } from 'utilities/constants';
import { handleErrorResponse } from 'utilities/errorHandlers';
import {
  percentageMask,
  percentageMaskOptions,
  phoneMask,
  ssn,
  ssnMaskOptions
} from 'utilities/masks';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { idTypes, stateOptions } from '../contents';
import { isComplete } from '../helpers';
import { beneficialOwnerSchema } from '../validation';
import AccountWrap from './AccountWrap/AccountWrap';
import BackLink from './BackLink/BackLink';

interface Params {
  reference_id: string;
}

interface CustomProps {
  dispatch: Dispatch;
  investmentAccount: SingleInvestmentAccount;
  submitting: boolean;
}
type PropsBeforeReduxForm = RouteComponentProps<Params> & CustomProps;

type Props = InjectedFormProps<object, PropsBeforeReduxForm> &
  PropsBeforeReduxForm;

const BeneficialNew = ({
  dispatch,
  handleSubmit,
  investmentAccount,
  match: { params },
  submitting
}: Props) => {
  useEffect(() => {
    dispatch(loadInvestmentAccount(params.reference_id)).catch(error =>
      handleErrorResponse(error)
    );
  }, [dispatch, params]);

  const onSubmit = values => {
    return dispatch(createBeneficialOwner(params.reference_id, values))
      .then(() => {
        if (isComplete(investmentAccount)) {
          history.push(
            `/accounts/update/${investmentAccount.account_type}/${params.reference_id}`
          );
        } else {
          history.push(
            `/accounts/wizard/${investmentAccount.account_type}/beneficial_owners/${params.reference_id}`
          );
        }
      })
      .catch(res => throwSubmissionErrors(res));
  };

  const handleUpload = file => {
    dispatch(change('beneficialOwner', 'beneficial_owner.document', file));
  };

  return (
    <AccountWrap loading={!investmentAccount} account={investmentAccount}>
      {investmentAccount && (
        <>
          <h4 className="margin-top-0">Add Beneficial Owner</h4>
          <p className="margin-xx">
            In order to comply with Know Your Customer (KYC) and Anti-Money
            Laundering (AML) rules and policies, we are required to collect
            additional information on every beneficial owner who owns 25% or
            more of your entity.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="margin-row">
              <Col md={6}>
                <Field
                  name="beneficial_owner.title"
                  component={ReduxFormInputField}
                  label="Title"
                />
              </Col>
              <Col md={6}>
                <Field
                  name="beneficial_owner.percentage_ownership"
                  mask={percentageMask}
                  inputMaskOptions={percentageMaskOptions}
                  component={ReduxFormInputField}
                  label="Percentage Ownership"
                />
              </Col>
            </Row>
            <Row className="margin-row">
              <Col md={6}>
                <Field
                  name="beneficial_owner.first_name"
                  component={ReduxFormInputField}
                  label="First Name"
                />
              </Col>
              <Col md={6}>
                <Field
                  name="beneficial_owner.last_name"
                  component={ReduxFormInputField}
                  label="Last Name"
                />
              </Col>
            </Row>
            <Row className="margin-row">
              <Col md={6} className="overflow-visible">
                <Field
                  name="beneficial_owner.dob"
                  id="beneficial_owner.dob"
                  component={ReduxFormDateSelectField}
                  label="Date of Birth"
                />
              </Col>
              <Col md={6}>
                <Field
                  name="beneficial_owner.ssn"
                  mask={ssn}
                  inputMaskOptions={ssnMaskOptions}
                  component={ReduxFormInputField}
                  label="SSN"
                />
              </Col>
            </Row>
            <Row className="margin-row">
              <Col md={6}>
                <Field
                  name="beneficial_owner.email"
                  component={ReduxFormInputField}
                  label="Email"
                />
              </Col>
              <Col md={6}>
                <Field
                  name="beneficial_owner.phone"
                  mask={phoneMask}
                  component={ReduxFormInputField}
                  label="Phone Number"
                />
              </Col>
            </Row>
            <h5>Address Information</h5>
            <Row className="margin-row">
              <Col md={6}>
                <Field
                  name="beneficial_owner.address"
                  component={ReduxFormInputField}
                  label="Address"
                />
              </Col>
              <Col md={6}>
                <Field
                  name="beneficial_owner.address2"
                  component={ReduxFormInputField}
                  label="Address 2"
                  placeholder="Apartment number, etc."
                />
              </Col>
            </Row>
            <Row className="margin-row">
              <Col md={4}>
                <Field
                  name="beneficial_owner.city"
                  component={ReduxFormInputField}
                  label="City"
                />
              </Col>
              <Col md={4} className="overflow-visible">
                <Field
                  name="beneficial_owner.state"
                  id="beneficial_owner_state"
                  component={ReduxFormSelectField}
                  label="State"
                  options={stateOptions}
                />
              </Col>
              <Col md={4}>
                <Field
                  name="beneficial_owner.postal_code"
                  component={ReduxFormInputField}
                  mask="99999"
                  label="Postal Code"
                />
              </Col>
            </Row>
            <h5>Identification Document</h5>
            <Row className="margin-row">
              <Col md={6}>
                <Field
                  name="beneficial_owner.document"
                  id="beneficial_owner_document"
                  component={ReduxFormFileUploaderField}
                  acceptedFileTypes={['JPG', 'PNG']}
                  maxSize={maxUploadSize}
                  uploading={submitting}
                  upload={handleUpload}
                  showImage
                />
              </Col>
              <Col md={6} className="overflow-visible">
                <Field
                  name="beneficial_owner.document_type"
                  id="beneficial_owner_document_type"
                  component={ReduxFormSelectField}
                  label="Document Type"
                  options={idTypes.map(key => ({
                    label: key.label,
                    value: key.value
                  }))}
                />
              </Col>
            </Row>

            <div className="forwardBackButtonWrapCompact">
              <Button type="submit" style={{ width: 160 }} loading={submitting}>
                Add
              </Button>
              <BackLink
                investmentAccount={investmentAccount}
                page="New Beneficial Owner"
              />
            </div>
          </form>
        </>
      )}
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  return {
    investmentAccount: state.account.investmentAccount,
    initialValues: {
      beneficial_owner: {
        beneficialOwners: state.account.investmentAccount?.beneficial_owners
      }
    }
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<object, Props>({
    form: 'beneficialOwner',
    enableReinitialize: true,
    touchOnBlur: false,
    onSubmitFail: scrollToError,
    validate: validateSchema(beneficialOwnerSchema)
  })(BeneficialNew)
);
