import { User } from 'types/api/user';
import { getLastCompletedStep, mustCompleteProfile } from 'utilities/user';

import { accountStatus, idTypes } from './contents';

export const formatDate = date => {
  if (date) {
    const dateArray = date.split('-');
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
  }

  return '';
};

export const isComplete = ia =>
  [accountStatus.created, accountStatus.flagged].includes(ia?.status);

export const getIdTypeLabel = document => {
  if (document) {
    const docIdType = idTypes.find(idType => idType.value === document.type);
    if (docIdType) return docIdType.label;
    return null;
  }
  return null;
};

export const supportsBeneficialOwners = account =>
  account && account.business_type !== 'soleproprietorship';

export const sortBeneficialOwners = beneficialOwners => {
  let sortedBeneficialOwners = beneficialOwners;
  if (beneficialOwners && beneficialOwners.length > 1)
    sortedBeneficialOwners = beneficialOwners.sort(
      (ownerA, ownerB) => ownerA.id - ownerB.id
    );
  return sortedBeneficialOwners;
};

export const hasShellInvestmentAccount = (accounts, accountType?) =>
  accounts?.some(account => {
    const accountTypeMatches = accountType
      ? account.type === accountType
      : true;

    if (accountTypeMatches && account.type === 'individual')
      return !account.is_signed_w9;

    if (accountTypeMatches && account.type === 'entity')
      return !account.entity_name;

    return accountTypeMatches && account.status === accountStatus.pending;
  });

export const newAccountRoute = (accountType, referenceId) => {
  switch (accountType) {
    case 'individual':
      return `/accounts/wizard/individual/w9/${referenceId}`;
    case 'joint account':
      return `/accounts/wizard/joint/secondary_account/${referenceId}`;
    case 'entity':
      return `/accounts/wizard/entity/information/${referenceId}`;
    case 'ira':
      return `/accounts/wizard/ira/${referenceId}`;
  }
  return null;
};

export const cannotLinkNewBankAccountReason = investmentAccount => {
  const unverifiedCount = investmentAccount?.bank_accounts?.filter(
    account => account?.status === 'unverified'
  ).length;

  if (!investmentAccount?.can_link_bank_account)
    return 'We have not yet been able to verify your investment account. Prior to linking a bank account we must verify it. To receive assistance with this problem, please contact our investor relations team at help@equitymultiple.com';
  if (investmentAccount?.bank_accounts?.length > 2)
    return 'You have reached the maximum number of bank accounts linked.  To link a new one, remove one and attempt again.';
  if (unverifiedCount > 0)
    return 'Please verify the existing bank accounts to add another.';

  return null;
};

export const getIncompleteAccountLink = (account, user?: User) => {
  if (user) {
    if (mustCompleteProfile(user)) return '/';
  }

  const type = account.account_type;
  const referenceId = account.reference_id;
  const baseRoute = `/accounts/wizard/${type}/`;
  let subRoute = '';

  if (type === 'individual') {
    // W9
    if (
      !account.is_signed_w9 ||
      !account.tax_filing_status ||
      !account.dwolla_customer_id
    )
      subRoute = 'w9/';
    // New Bank Account
    else subRoute = 'link_bank/';
  }

  if (type === 'joint') {
    // Information
    if (!account.account_holder_2_first_name) subRoute = 'secondary_account/';
    else if (!account.account_holder_2_id_document)
      subRoute = 'secondary_account/identification_document/';
    // W9
    else if (!account.is_signed_w9 || !account.tax_filing_status)
      subRoute = 'w9/';
    // Power of Attorney
    else if (account.poa_agreement_status !== 'Complete') subRoute = 'poa/';
    // New Bank Account
    else subRoute = 'link_bank/';
  }

  if (type === 'entity') {
    // Information
    if (!account.entity_name) subRoute = 'information/';
    // Organizational Document
    else if (!account.entity_evidence_attachment)
      subRoute = 'organizational_document/';
    // Tax Document
    else if (!account.ein_evidence_attachment) subRoute = 'tax_document/';
    // Beneficial Owners && W9
    // Beneficial owners step is optional, so send them here if it's not a sole proprietorship and there's no W9
    else if (!account.is_signed_w9) subRoute = 'w9/';
    // New Bank Account
    else subRoute = 'link_bank/';
  }

  // IRA needs no subRoute
  return baseRoute + subRoute + referenceId;
};

export const signupSteps = [
  '/signup/email',
  '/signup/password',
  '/signup/name',
  '/signup/accreditation',
  '/signup/phone',
  '/signup/discover',
  '/signup/email_verify'
];

export const profileSteps = [
  '/profile/experience',
  '/profile/objectives',
  '/profile/tolerance',
  '/profile/name',
  '/profile/kyc',
  '/profile/address',
  '/profile/document_identification'
];

// This includes steps that we don't take into account when determining a user's completion percentage,
// but do use for routing the user to the first incomplete step
const signupStepsForRouting = [
  ...signupSteps,
  '/profile/start',
  ...profileSteps
];

export const getAccountCompletionPercentage = account => {
  const incompleteAccountLink = getIncompleteAccountLink(account);
  const pathSegments = incompleteAccountLink.split('/');
  const type = account.account_type;
  const nextPath = pathSegments[pathSegments.length - 2];

  let steps;

  if (type === 'individual') {
    steps = ['w9', 'link_bank'];
  }

  if (type === 'joint') {
    steps = [
      'secondary_account',
      'identification_document',
      'w9',
      'poa',
      'link_bank'
    ];
  }

  if (type === 'entity') {
    steps = [
      'information',
      'organizational_document',
      'tax_document',
      'w9',
      'link_bank'
    ];
  }

  const completedStepCount = steps.indexOf(nextPath);
  const stepCount = steps.length;

  return Math.floor((completedStepCount / stepCount) * 100);
};

export const getFirstIncompleteStepRoute = (user: User) => {
  const lastCompletedStep = getLastCompletedStep(user);
  const lastCompletedStepIndex =
    signupStepsForRouting.indexOf(lastCompletedStep);
  const nextStepRoute = signupStepsForRouting[lastCompletedStepIndex + 1];

  if (nextStepRoute) return `/users${nextStepRoute}`;
  else return '/';
};
