import React from 'react';

import ArticleCard from '../../../../components/ArticleCard/ArticleCard';
import * as styles from './Articles.module.scss';

const Articles = () => (
  <div className={styles.articles}>
    <ArticleCard
      link="https://equitymultiple.com/wp-content/uploads/2024/07/Sample-Starter-Portfolios-Foundations-Alpine-and-Ascent.pdf"
      title="[Case Study] Sample Starter Portfolios: Foundations, Ascent, Alpine"
      className={styles.articleCard}
    />
    <ArticleCard
      link="https://equitymultiple.com/wp-content/uploads/2024/05/full-track-record-2Q2024.pdf"
      title="Full Investment-Level Track Record"
      className={styles.articleCard}
    />
    <ArticleCard
      link="https://equitymultiple.com/wp-content/uploads/2024/07/Diversification-A-Comprehensive-Guide.pdf"
      title="Diversification: A Comprehensive Guide"
      className={styles.articleCard}
    />
  </div>
);

export default Articles;
