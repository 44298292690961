import { Button } from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  loadInvestmentAccount,
  removeBeneficialOwner
} from 'redux/actions/account';
import { SingleInvestmentAccount } from 'types/api/account';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { handleErrorResponse } from 'utilities/errorHandlers';
import humane from 'utilities/humane';
import utils from 'utilities/utils';

import * as sharedStyles from '../../AccountShared.module.scss';
import { formatDate, sortBeneficialOwners } from '../../helpers';
import AccountWrap from '../AccountWrap/AccountWrap';
import BackLink from '../BackLink/BackLink';
import * as styles from './BeneficialOwners.module.scss';

interface Params {
  reference_id: string;
}

interface CustomProps {
  dispatch: Dispatch;
  investmentAccount: SingleInvestmentAccount;
  loading: boolean;
}

type Props = RouteComponentProps<Params> & CustomProps;

const BeneficialOwners = ({
  dispatch,
  investmentAccount,
  match: { params },
  loading
}: Props) => {
  useEffect(() => {
    document.title = 'My Accounts | Beneficial Owners';
    dispatch(loadInvestmentAccount(params.reference_id)).catch(error =>
      handleErrorResponse(error)
    );
  }, [dispatch, params]);

  const removeBeneficiary = id => {
    dispatch(removeBeneficialOwner(id))
      .then(res => {
        humane.notice(res.message);
        dispatch(loadInvestmentAccount(params.reference_id));
      })
      .catch(err => {
        humane.error(err.body.message);
      });
  };

  const trackSubmission = () => {
    EmAnalytics.track(
      'Submits Entity Account Beneficial Owners',
      'Onboarding',
      {
        account_type: utils.startCase(investmentAccount.account_type)
      }
    );
  };

  const newBeneficialAllowed =
    !investmentAccount?.beneficial_owners ||
    investmentAccount.beneficial_owners.length < 4;
  const sortedBeneficialOwners = sortBeneficialOwners(
    investmentAccount?.beneficial_owners
  );

  return (
    <AccountWrap
      loading={loading || !investmentAccount}
      account={investmentAccount}
    >
      {investmentAccount && (
        <>
          <h4 className="margin-top-0">
            Are there other beneficial owners who own 25% or more of this
            entity?
          </h4>
          <p>
            In order to comply with Know Your Customer (KYC) and Anti-Money
            Laundering (AML) rules and policies, we are required to collect
            additional information on every beneficial owner who owns 25% or
            more of your entity.
          </p>
          {sortedBeneficialOwners.length ? (
            <div>
              {sortedBeneficialOwners.map((representative, index) => (
                <div key={representative.id} className={sharedStyles.account}>
                  <Row>
                    <Col md={4}>
                      <div className="text-label">Name</div>
                      <p>
                        {representative.first_name +
                          ' ' +
                          representative.last_name}
                      </p>
                    </Col>
                    <Col md={4}>
                      <div className="text-label">Email</div>
                      <p>{representative.email}</p>
                    </Col>
                    <Col md={4}>
                      <div className="text-label">Phone Number</div>
                      <p>{representative.phone}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <div className="text-label">SSN</div>
                      <p>{representative.masked_ssn}</p>
                    </Col>
                    <Col md={4}>
                      <div className="text-label">Date of Birth</div>
                      <p>{formatDate(representative.dob)}</p>
                    </Col>
                    <Col md={4}>
                      <Link
                        className="underline"
                        to={`/accounts/wizard/${investmentAccount.account_type}/beneficial_owners/${representative.id}/${investmentAccount.reference_id}`}
                      >
                        Edit
                      </Link>
                      {index !== 0 && (
                        <button
                          className={`text-link danger ${styles.removeButton}`}
                          onClick={() => removeBeneficiary(representative.id)}
                          type="button"
                        >
                          Remove
                        </button>
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          ) : (
            <p>
              Click Add Beneficial Owner to add individuals or entities who own
              25% or more of this entity.
            </p>
          )}
          {newBeneficialAllowed && (
            <div className={styles.newItemButtonWrap}>
              <Button
                variant="outlined"
                wrapper={
                  <Link
                    to={`/accounts/wizard/${investmentAccount.account_type}/beneficial_owners/new/${investmentAccount.reference_id}`}
                  />
                }
              >
                Add Beneficial Owner
              </Button>
            </div>
          )}

          <div className="forwardBackButtonWrapCompact">
            <Button
              onClick={trackSubmission}
              style={{ width: 160 }}
              variant="orange"
              wrapper={
                <Link
                  to={`/accounts/wizard/${investmentAccount.account_type}/w9/${params.reference_id}`}
                />
              }
            >
              {investmentAccount.beneficial_owners.length ? 'Continue' : 'Skip'}
            </Button>
            <BackLink
              investmentAccount={investmentAccount}
              page="Beneficial Owners"
            />
          </div>
        </>
      )}
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  return {
    investmentAccount: state.account.investmentAccount,
    loading: state.account.loading
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(BeneficialOwners);
